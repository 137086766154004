// src/components/RegisterPage.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import githubIcon from '../components/img/github.png';
import linkedinIcon from '../components/img/linkedin.png';
import NavbarNotLoginYet from '../components/navbars/navbar_notlogin_yet';
import Footer from '../components/footer/footer';

const RegisterPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const handleRegister = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            alert("Les mots de passe ne correspondent pas");
            return;
        }

        try {
            await axios.post('http://185.216.27.46:8080/api/register', { email, password });
            setIsPopupVisible(true); // Afficher la popup après une inscription réussie
        } catch (error) {
            console.error("Erreur lors de l'inscription:", error.response ? error.response.data : error.message);
            alert("Erreur lors de l'inscription. Veuillez réessayer.");
        }
    };

    const handleClosePopup = () => {
        setIsPopupVisible(false);
        navigate('/login'); // Redirige vers la page de connexion après fermeture de la popup
    };

    return (
        <div className="h-screen overflow-y-auto bg-gradient-to-r from-blue-800 to-indigo-900 relative">
            <NavbarNotLoginYet />

            {/* Popup Modal */}
            {isPopupVisible && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-80 max-w-sm text-center">
                        <h3 className="text-2xl font-semibold text-blue-800 mb-4">Inscription réussie !</h3>
                        <p className="text-gray-700 mb-6">Vous êtes maintenant inscrit.</p>
                        <button
                            onClick={handleClosePopup}
                            className="px-4 py-2 bg-indigo-700 text-white rounded-lg shadow-md hover:bg-indigo-800 transition duration-300"
                        >
                            Aller à la connexion
                        </button>
                    </div>
                </div>
            )}

            {/* Decorative Dots and Colors */}
            <div className="absolute top-0 right-0 w-40 h-40 bg-blue-300 rounded-full blur-3xl opacity-30 transform translate-x-20 -translate-y-20 z-10"></div>
            <div className="absolute bottom-0 left-0 w-40 h-40 bg-indigo-300 rounded-full blur-3xl opacity-30 transform -translate-x-20 translate-y-20 z-10"></div>
            <div className="absolute top-20 right-10 grid grid-cols-5 gap-6">
                {Array.from({ length: 25 }).map((_, index) => (
                    <div key={index} className="w-1 h-1 bg-white rounded-full"></div>
                ))}
            </div>
            <div className="absolute bottom-20 left-10 grid grid-cols-5 gap-6">
                {Array.from({ length: 25 }).map((_, index) => (
                    <div key={index} className="w-1 h-1 bg-white rounded-full"></div>
                ))}
            </div>

            {/* Register Form Section */}
            <div className="flex justify-center items-center mt-20 mb-20 px-[8%]">
                <div className="bg-white p-10 rounded-3xl shadow-2xl max-w-md w-full">
                    <h2 className="text-3xl font-extrabold text-blue-900 mb-6 text-center">Inscription</h2>
                    <form className="space-y-4" onSubmit={handleRegister}>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                            <input
                                className="w-full py-3 px-4 rounded-lg shadow-inner border border-gray-300 focus:outline-none focus:border-indigo-500 transition duration-300"
                                id="email"
                                type="email"
                                placeholder="Votre email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">Mot de passe</label>
                            <input
                                className="w-full py-3 px-4 rounded-lg shadow-inner border border-gray-300 focus:outline-none focus:border-indigo-500 transition duration-300"
                                id="password"
                                type="password"
                                placeholder="Votre mot de passe"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="confirm-password" className="block text-gray-700 text-sm font-bold mb-2">Confirmez le mot de passe</label>
                            <input
                                className="w-full py-3 px-4 rounded-lg shadow-inner border border-gray-300 focus:outline-none focus:border-indigo-500 transition duration-300"
                                id="confirm-password"
                                type="password"
                                placeholder="Confirmez votre mot de passe"
                                required
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full py-3 bg-indigo-700 text-white font-bold rounded-lg shadow-md hover:bg-indigo-800 transition duration-300 ease-in-out"
                        >
                            S'inscrire
                        </button>
                    </form>
                    <div className="flex justify-center mt-6 space-x-4">
                        <a href="https://github.com" target="_blank" rel="noopener noreferrer">
                            <img src={githubIcon} alt="GitHub" className="w-8 h-8 transition-transform transform hover:scale-110" />
                        </a>
                        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                            <img src={linkedinIcon} alt="LinkedIn" className="w-8 h-8 transition-transform transform hover:scale-110" />
                        </a>
                    </div>
                    <p className="text-sm text-gray-500 text-center mt-4">
                        Vous avez déjà un compte ?{" "}
                        <a href="/login" className="text-indigo-600 hover:underline">Connectez-vous</a>
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default RegisterPage;
