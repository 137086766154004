// src/components/PricingPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import NavbarLoginYes from '../components/navbars/navbar_login_yes';
import NavbarNotLoginYet from '../components/navbars/navbar_notlogin_yet';
import Footer from '../components/footer/footer';

const PricingPage = () => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();

    return (
        <div className="h-screen overflow-y-auto bg-gradient-to-r from-blue-800 to-indigo-900 relative">
            
            {isAuthenticated ? <NavbarLoginYes /> : <NavbarNotLoginYet />}

            {/* Decorative Dots and Colors */}
            <div className="absolute top-0 right-0 w-40 h-40 bg-blue-300 rounded-full blur-3xl opacity-30 transform translate-x-20 -translate-y-20 z-10"></div>
            <div className="absolute bottom-0 left-0 w-40 h-40 bg-indigo-300 rounded-full blur-3xl opacity-30 transform -translate-x-20 translate-y-20 z-10"></div>
            <div className="absolute top-20 right-10 grid grid-cols-5 gap-6">
                {Array.from({ length: 25 }).map((_, index) => (
                    <div key={index} className="w-1 h-1 bg-white rounded-full"></div>
                ))}
            </div>

            {/* Pricing Section */}
            <div className="flex justify-center items-center mt-20 mb-20 px-[8%]">
                <div className="bg-white p-10 rounded-3xl shadow-2xl max-w-lg w-full text-center">
                    <h2 className="text-4xl font-extrabold text-blue-900 mb-4">Prix</h2>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        Notre générateur de CV est actuellement <span className="font-bold text-blue-700">gratuit</span> car nous sommes en phase de test.
                    </p>
                    <p className="text-md text-gray-500 mt-6 mb-8">
                        Profitez-en pour créer votre CV sans frais et nous aider à améliorer l'outil en partageant vos retours.
                    </p>
                    <button
                        onClick={() => navigate('/contact')}
                        className="mt-4 px-8 py-3 bg-indigo-700 text-white font-bold rounded-lg shadow-md hover:bg-indigo-800 transition duration-300 ease-in-out"
                    >
                        Nous faire un retour
                    </button>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default PricingPage;
