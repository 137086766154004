import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import githubIcon from '../components/img/github.png';
import linkedinIcon from '../components/img/linkedin.png';
import { useAuth } from '../contexts/AuthContext';
import NavbarLoginYes from '../components/navbars/navbar_login_yes';
import NavbarNotLoginYet from '../components/navbars/navbar_notlogin_yet';
import Footer from '../components/footer/footer';

const ContactPage = () => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('http://185.216.27.46:8081/bckapi/bckapi/send-email', formData);
            setIsPopupVisible(true); // Afficher la popup après envoi réussi
            console.log("Popup should be visible now"); // Vérification
        } catch (error) {
            console.error("Erreur lors de l'envoi du message:", error);
        }
    };

    const handleClosePopup = () => {
        setIsPopupVisible(false);
        navigate('/'); // Redirige vers la page d'accueil
    };

    return (
        <div className="h-screen overflow-y-auto bg-gradient-to-r from-blue-800 to-indigo-900 relative">
            {isAuthenticated ? <NavbarLoginYes /> : <NavbarNotLoginYet />}

            {/* Popup Modal */}
            {isPopupVisible && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ease-in-out">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-80 max-w-sm text-center transform transition-transform duration-300 ease-in-out scale-100">
                        <h3 className="text-2xl font-semibold text-blue-800 mb-4">Message envoyé !</h3>
                        <p className="text-gray-700 mb-6">Votre message a été envoyé avec succès.</p>
                        <button
                            onClick={handleClosePopup}
                            className="px-4 py-2 bg-indigo-700 text-white rounded-lg shadow-md hover:bg-indigo-800 transition duration-300"
                        >
                            Retour à l'accueil
                        </button>
                    </div>
                </div>
            )}

            {/* Decorative Dots and Colors */}
            <div className="absolute top-0 right-0 w-40 h-40 bg-blue-300 rounded-full blur-3xl opacity-30 transform translate-x-20 -translate-y-20 z-10"></div>
            <div className="absolute bottom-0 left-0 w-40 h-40 bg-indigo-300 rounded-full blur-3xl opacity-30 transform -translate-x-20 translate-y-20 z-10"></div>
            <div className="absolute top-20 right-10 grid grid-cols-5 gap-6">
                {Array.from({ length: 25 }).map((_, index) => (
                    <div key={index} className="w-1 h-1 bg-white rounded-full"></div>
                ))}
            </div>
            <div className="absolute bottom-20 left-10 grid grid-cols-5 gap-6">
                {Array.from({ length: 25 }).map((_, index) => (
                    <div key={index} className="w-1 h-1 bg-white rounded-full"></div>
                ))}
            </div>

            {/* Contact Form Section */}
            <div className="flex justify-center items-center mt-20 mb-20 px-[8%]">
                <div className="bg-white p-10 rounded-3xl shadow-2xl max-w-2xl w-full">
                    <h2 className="text-3xl font-extrabold text-blue-900 mb-6 text-center">Contactez-nous</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">Nom</label>
                            <input
                                className="w-full py-3 px-4 rounded-lg shadow-inner border border-gray-300 focus:outline-none focus:border-indigo-500 transition duration-300"
                                id="name"
                                type="text"
                                placeholder="Votre nom"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email</label>
                            <input
                                className="w-full py-3 px-4 rounded-lg shadow-inner border border-gray-300 focus:outline-none focus:border-indigo-500 transition duration-300"
                                id="email"
                                type="email"
                                placeholder="Votre email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">Message</label>
                            <textarea
                                className="w-full py-3 px-4 rounded-lg shadow-inner border border-gray-300 focus:outline-none focus:border-indigo-500 transition duration-300"
                                id="message"
                                rows="4"
                                placeholder="Votre message"
                                value={formData.message}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                        <button
                            type="submit"
                            className="w-full py-3 bg-indigo-700 text-white font-bold rounded-lg shadow-md hover:bg-indigo-800 transition duration-300 ease-in-out"
                        >
                            Envoyer
                        </button>
                    </form>

                    {/* Social Icons */}
                    <div className="flex justify-center mt-6 space-x-4">
                        <a href="https://github.com" target="_blank" rel="noopener noreferrer">
                            <img src={githubIcon} alt="GitHub" className="w-8 h-8 transition-transform transform hover:scale-110" />
                        </a>
                        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                            <img src={linkedinIcon} alt="LinkedIn" className="w-8 h-8 transition-transform transform hover:scale-110" />
                        </a>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ContactPage;
