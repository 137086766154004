// src/components/CVBasicForm.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import NavbarLoginYes from '../components/navbars/navbar_login_yes';
import NavbarNotLoginYet from '../components/navbars/navbar_notlogin_yet';
import Footer from '../components/footer/footer';

const CVBasicForm = () => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const [formData, setFormData] = useState({
        name: '',
        prenom: '',
        email: '',
        age: '',
        phone: '',
        adress: '',
        permis: false,
        experiences: [{ title: '', company: '', start_date: '', end_date: '', description: '' }],
        education: [{ degree: '', school: '', start_date: '', end_date: '' }],
        skills: [''],
        centreinteret: [''],
        langues: [''],
        image_path: '',
        cvcolor: '',
    });

    const [step, setStep] = useState(1);

    // Gestion des changements de champs pour le formulaire
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : (name === 'age' ? parseInt(value) || '' : value),
        });
    };

    const handleColorChange = (e) => {
        setFormData({
            ...formData,
            cvcolor: e.target.value,
        });
    };


    const handlePermisChange = (value) => {
        setFormData({
            ...formData,
            permis: value === 'oui',
        });
    };

    // Gestion des expériences
    const addExperience = () => {
        if (formData.experiences.length < 5) {
            setFormData({
                ...formData,
                experiences: [...formData.experiences, { title: '', company: '', start_date: '', end_date: '', description: '' }],
            });
        }
    };

    const handleExperienceChange = (index, e) => {
        const { name, value } = e.target;
        const updatedExperiences = formData.experiences.map((exp, i) =>
            i === index ? { ...exp, [name]: value } : exp
        );
        setFormData({ ...formData, experiences: updatedExperiences });
    };

    const removeExperience = (index) => {
        const updatedExperiences = formData.experiences.filter((_, i) => i !== index);
        setFormData({ ...formData, experiences: updatedExperiences });
    };

    // Gérer le changement de fichier
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
            const formDataFile = new FormData();
            formDataFile.append('file', file);

            try {
                const response = await axios.post('http://cvflash.fr:8080/api/upload-image', formDataFile, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });

                if (response.data && response.data.imagePath) {
                    setFormData({
                        ...formData,
                        image_path: response.data.imagePath, // Met à jour le chemin de l'image
                    });
                }
            } catch (error) {
                console.error("Erreur lors du téléchargement de l'image:", error);
                alert("Erreur lors du téléchargement de l'image. Veuillez réessayer.");
            }
        } else {
            alert("Veuillez télécharger un fichier PNG ou JPG.");
        }
    };

    // Gestion de l'éducation
    const addEducation = () => {
        if (formData.education.length < 5) {
            setFormData({
                ...formData,
                education: [...formData.education, { degree: '', school: '', start_date: '', end_date: '' }],
            });
        }
    };

    const handleEducationChange = (index, e) => {
        const { name, value } = e.target;
        const updatedEducation = formData.education.map((edu, i) =>
            i === index ? { ...edu, [name]: value } : edu
        );
        setFormData({ ...formData, education: updatedEducation });
    };

    const removeEducation = (index) => {
        const updatedEducation = formData.education.filter((_, i) => i !== index);
        setFormData({ ...formData, education: updatedEducation });
    };

    // Gestion des compétences
    const addSkill = () => {
        setFormData({ ...formData, skills: [...formData.skills, ''] });
    };

    const handleSkillChange = (index, e) => {
        const updatedSkills = formData.skills.map((skill, i) =>
            i === index ? e.target.value : skill
        );
        setFormData({ ...formData, skills: updatedSkills });
    };

    const removeSkill = (index) => {
        const updatedSkills = formData.skills.filter((_, i) => i !== index);
        setFormData({ ...formData, skills: updatedSkills });
    };

    // Gestion des centres d'intérêt
    const addCentreInteret = () => {
        setFormData({ ...formData, centreinteret: [...formData.centreinteret, ''] });
    };

    const handleCentreInteretChange = (index, e) => {
        const updatedCentreInteret = formData.centreinteret.map((interest, i) =>
            i === index ? e.target.value : interest
        );
        setFormData({ ...formData, centreinteret: updatedCentreInteret });
    };

    const removeCentreInteret = (index) => {
        const updatedCentreInteret = formData.centreinteret.filter((_, i) => i !== index);
        setFormData({ ...formData, centreinteret: updatedCentreInteret });
    };

    // Gestion des langues
    const addLangue = () => {
        setFormData({ ...formData, langues: [...formData.langues, ''] });
    };

    const handleLangueChange = (index, e) => {
        const updatedLangues = formData.langues.map((langue, i) =>
            i === index ? e.target.value : langue
        );
        setFormData({ ...formData, langues: updatedLangues });
    };

    const removeLangue = (index) => {
        const updatedLangues = formData.langues.filter((_, i) => i !== index);
        setFormData({ ...formData, langues: updatedLangues });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (step < 4) {
            setStep(step + 1);
        } else {
            console.log("Données envoyées au backend:", formData);
    
            axios.post('http://cvflash.fr:8080/api/generate-cv', formData)
                .then((response) => {
                    console.log("Réponse du serveur:", response.data);
                    if (response.data && response.data.success) {
                        navigate('/download', { state: { filePath: response.data.file_path } }); // Redirection avec le chemin du fichier
                    } else {
                        alert("Erreur lors de la génération du CV. Vérifiez la réponse du backend.");
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.error("Erreur du serveur:", error.response.data);
                        alert(`Erreur ${error.response.status}: ${error.response.data.message || "Problème lors de la génération du CV"}`);
                        if (error.response.data.errors) {
                            alert("Détails des erreurs: " + JSON.stringify(error.response.data.errors));
                        }
                    } else if (error.request) {
                        console.error("Pas de réponse reçue du serveur", error.request);
                        alert("Aucune réponse du serveur. Vérifiez que le serveur est en cours d'exécution.");
                    } else {
                        console.error("Erreur de requête:", error.message);
                        alert("Erreur de requête: " + error.message);
                    }
                });
        }
    };
    
    return (
        <div className="h-screen overflow-y-auto bg-gradient-to-r from-blue-800 to-indigo-900 relative">
            
            {isAuthenticated ? <NavbarLoginYes /> : <NavbarNotLoginYet />}

            {/* Formulaire de CV */}
            <div className="flex justify-center items-center mt-20 mb-20 px-[8%]">
                <div className="bg-white p-10 rounded-3xl shadow-2xl max-w-3xl w-full ">
                    <h2 className="text-3xl font-extrabold text-blue-900 mb-6 text-center">Créer votre CV</h2>

                                        {/* Section de sélection de couleur */}
                        
                    {step === 1 && (
                    <form onSubmit={handleSubmit}>
                    <div className="mb-6">
                        <h3 className="text-2xl font-semibold text-blue-800 mb-2">Choisissez la couleur du CV (Obligatoire)</h3>
                        <div className="flex items-center space-x-2">
                            {/* Sélecteur de couleur personnalisé */}
                            <label className="flex items-center space-x-2">
                                <input
                                    type="color"
                                    name="cvcolor"
                                    value={formData.cvcolor}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            cvcolor: e.target.value,
                                        });
                                    }}
                                    className="w-20 h-10 "
                                />
                            </label>
                        </div>
                    </div>


                    {/* Section de téléchargement de fichier */}
                    <div className="mb-6">
                        <h3 className="text-2xl font-semibold text-blue-800 mb-2">Télécharger une photo</h3>
                        <input
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={handleFileChange}
                            className="w-full py-2 px-4 rounded-lg border"
                        />
                        {formData.image_path && (
                            <p className="text-green-600 mt-2">Image téléchargée avec succès !</p>
                        )}
                    </div>
                            <h3 className="text-2xl font-semibold text-blue-800 mb-4">Informations personnelles</h3>
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">Nom</label>
                                <input id="name" name="name" type="text" value={formData.name} onChange={handleChange} className="w-full py-3 px-4 rounded-lg border" required />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="prenom" className="block text-gray-700 text-sm font-bold mb-2">Prénom</label>
                                <input id="prenom" name="prenom" type="text" value={formData.prenom} onChange={handleChange} className="w-full py-3 px-4 rounded-lg border" required />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                                <input id="email" name="email" type="email" value={formData.email} onChange={handleChange} className="w-full py-3 px-4 rounded-lg border" required />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="age" className="block text-gray-700 text-sm font-bold mb-2">Âge</label>
                                <input id="age" name="age" type="number" value={formData.age} onChange={handleChange} className="w-full py-3 px-4 rounded-lg border" required />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="phone" className="block text-gray-700 text-sm font-bold mb-2">Téléphone</label>
                                <input id="phone" name="phone" type="tel" value={formData.phone} onChange={handleChange} className="w-full py-3 px-4 rounded-lg border" />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="adress" className="block text-gray-700 text-sm font-bold mb-2">Adresse</label>
                                <input id="adress" name="adress" type="text" value={formData.adress} onChange={handleChange} className="w-full py-3 px-4 rounded-lg border" />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Avez-vous le permis B ?</label>
                                <div className="flex space-x-4">
                                    <label className="flex items-center space-x-2">
                                        <input type="radio" name="permis" value="oui" onChange={() => handlePermisChange('oui')} checked={formData.permis === true} />
                                        <span>Oui</span>
                                    </label>
                                    <label className="flex items-center space-x-2">
                                        <input type="radio" name="permis" value="non" onChange={() => handlePermisChange('non')} checked={formData.permis === false} />
                                        <span>Non</span>
                                    </label>
                                </div>
                            </div>
                            <button type="submit" className="w-full py-3 bg-indigo-700 text-white font-bold rounded-lg shadow-md hover:bg-indigo-800 transition duration-300 ease-in-out mt-6">
                                Suivant
                            </button>
                        </form>
                    )}

                    {step === 2 && (
                        <form onSubmit={handleSubmit}>
                            <h3 className="text-2xl font-semibold text-blue-800 mb-4">Expériences Professionnelles (Max 5)</h3>
                            {formData.experiences.map((experience, index) => (
                                <div key={index} className="mb-4 border-b pb-4">
                                    <div className="mb-2">
                                        <label htmlFor={`title-${index}`} className="block text-gray-700 text-sm font-bold mb-1">Titre</label>
                                        <input
                                            type="text"
                                            name="title"
                                            value={experience.title}
                                            onChange={(e) => handleExperienceChange(index, e)}
                                            className="w-full py-2 px-4 rounded-lg border"
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor={`company-${index}`} className="block text-gray-700 text-sm font-bold mb-1">Entreprise</label>
                                        <input
                                            type="text"
                                            name="company"
                                            value={experience.company}
                                            onChange={(e) => handleExperienceChange(index, e)}
                                            className="w-full py-2 px-4 rounded-lg border"
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor={`start_date-${index}`} className="block text-gray-700 text-sm font-bold mb-1">Date de début</label>
                                        <input
                                            type="text"
                                            name="start_date"
                                            placeholder="MM/YYYY"
                                            value={experience.start_date}
                                            onChange={(e) => handleExperienceChange(index, e)}
                                            className="w-full py-2 px-4 rounded-lg border"
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor={`end_date-${index}`} className="block text-gray-700 text-sm font-bold mb-1">Date de fin</label>
                                        <input
                                            type="text"
                                            name="end_date"
                                            placeholder="MM/YYYY"
                                            value={experience.end_date}
                                            onChange={(e) => handleExperienceChange(index, e)}
                                            className="w-full py-2 px-4 rounded-lg border"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor={`description-${index}`} className="block text-gray-700 text-sm font-bold mb-1">Description</label>
                                        <textarea
                                            name="description"
                                            value={experience.description}
                                            onChange={(e) => handleExperienceChange(index, e)}
                                            className="w-full py-2 px-4 rounded-lg border"
                                            rows="3"
                                            required
                                        ></textarea>
                                    </div>
                                    {formData.experiences.length > 1 && (
                                        <button
                                            type="button"
                                            onClick={() => removeExperience(index)}
                                            className="text-red-600 hover:text-red-800"
                                        >
                                            Supprimer cette expérience
                                        </button>
                                    )}
                                </div>
                            ))}
                            {formData.experiences.length < 5 ? (
                                <button
                                    type="button"
                                    onClick={addExperience}
                                    className="w-full py-2 bg-green-600 text-white rounded-lg shadow-md hover:bg-green-700 transition duration-300 ease-in-out"
                                >
                                    Ajouter une autre expérience
                                </button>
                            ) : (
                                <p className="text-gray-600 mt-2">Vous avez atteint le nombre maximum d'expériences professionnelles.</p>
                            )}
                            <button
                                type="submit"
                                className="w-full py-3 bg-indigo-700 text-white font-bold rounded-lg shadow-md hover:bg-indigo-800 transition duration-300 ease-in-out mt-6"
                            >
                                Suivant
                            </button>
                        </form>
                    )}


                    {step === 3 && (
                        <form onSubmit={handleSubmit}>
                            <h3 className="text-2xl font-semibold text-blue-800 mb-4">Éducation (Max 3)</h3>
                            {formData.education.map((edu, index) => (
                                <div key={index} className="mb-4 border-b pb-4">
                                    <div className="mb-2">
                                        <label htmlFor={`degree-${index}`} className="block text-gray-700 text-sm font-bold mb-1">Diplôme</label>
                                        <input
                                            type="text"
                                            name="degree"
                                            value={edu.degree}
                                            onChange={(e) => handleEducationChange(index, e)}
                                            className="w-full py-2 px-4 rounded-lg border"
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor={`school-${index}`} className="block text-gray-700 text-sm font-bold mb-1">École</label>
                                        <input
                                            type="text"
                                            name="school"
                                            value={edu.school}
                                            onChange={(e) => handleEducationChange(index, e)}
                                            className="w-full py-2 px-4 rounded-lg border"
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor={`start_date-${index}`} className="block text-gray-700 text-sm font-bold mb-1">Date de début</label>
                                        <input
                                            type="text"
                                            name="start_date"
                                            placeholder="MM/YYYY"
                                            value={edu.start_date}
                                            onChange={(e) => handleEducationChange(index, e)}
                                            className="w-full py-2 px-4 rounded-lg border"
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor={`end_date-${index}`} className="block text-gray-700 text-sm font-bold mb-1">Date de fin</label>
                                        <input
                                            type="text"
                                            name="end_date"
                                            placeholder="MM/YYYY"
                                            value={edu.end_date}
                                            onChange={(e) => handleEducationChange(index, e)}
                                            className="w-full py-2 px-4 rounded-lg border"
                                        />
                                    </div>
                                    {formData.education.length > 1 && (
                                        <button
                                            type="button"
                                            onClick={() => removeEducation(index)}
                                            className="text-red-600 hover:text-red-800"
                                        >
                                            Supprimer cette formation
                                        </button>
                                    )}
                                </div>
                            ))}
                            {formData.education.length < 3 ? (
                                <button
                                    type="button"
                                    onClick={addEducation}
                                    className="w-full py-2 bg-green-600 text-white rounded-lg shadow-md hover:bg-green-700 transition duration-300 ease-in-out"
                                >
                                    Ajouter une autre formation
                                </button>
                            ) : (
                                <p className="text-gray-600 mt-2">Vous avez atteint le nombre maximum de formations.</p>
                            )}
                            <button
                                type="submit"
                                className="w-full py-3 bg-indigo-700 text-white font-bold rounded-lg shadow-md hover:bg-indigo-800 transition duration-300 ease-in-out mt-6"
                            >
                                Suivant
                            </button>
                        </form>
                    )}


{step === 4 && (
    <form onSubmit={handleSubmit}>
        <h3 className="text-2xl font-semibold text-blue-800 mb-4">Compétences, Langues et Centres d'Intérêt</h3>

        <div className="mb-6">
            <h4 className="text-xl font-semibold text-gray-800 mb-2">Compétences</h4>
            {formData.skills.map((skill, index) => (
                <div key={index} className="flex items-center mb-2">
                    <input
                        type="text"
                        value={skill}
                        onChange={(e) => handleSkillChange(index, e)}
                        className="w-full py-2 px-4 rounded-lg border"
                        placeholder="Compétence"
                    />
                    {formData.skills.length > 1 && (
                        <button type="button" onClick={() => removeSkill(index)} className="ml-2 text-red-600 hover:text-red-800">
                            Supprimer
                        </button>
                    )}
                </div>
            ))}
            <button type="button" onClick={addSkill} className="w-full py-2 bg-green-600 text-white rounded-lg shadow-md hover:bg-green-700 transition duration-300 ease-in-out">
                Ajouter une compétence
            </button>
        </div>

        <div className="mb-6">
            <h4 className="text-xl font-semibold text-gray-800 mb-2">Langues (Max 6)</h4>
            {formData.langues.map((langue, index) => (
                <div key={index} className="flex items-center mb-2">
                    <input
                        type="text"
                        value={langue}
                        onChange={(e) => handleLangueChange(index, e)}
                        className="w-full py-2 px-4 rounded-lg border"
                        placeholder="Langue"
                    />
                    {formData.langues.length > 1 && (
                        <button type="button" onClick={() => removeLangue(index)} className="ml-2 text-red-600 hover:text-red-800">
                            Supprimer
                        </button>
                    )}
                </div>
            ))}
            {formData.langues.length < 6 ? (
                <button
                    type="button"
                    onClick={addLangue}
                    className="w-full py-2 bg-green-600 text-white rounded-lg shadow-md hover:bg-green-700 transition duration-300 ease-in-out"
                >
                    Ajouter une langue
                </button>
            ) : (
                <p className="text-gray-600 mt-2">Vous avez atteint le nombre maximum de langues.</p>
            )}
        </div>

        <div className="mb-6">
            <h4 className="text-xl font-semibold text-gray-800 mb-2">Centres d'Intérêt (Max 6)</h4>
            {formData.centreinteret.map((interest, index) => (
                <div key={index} className="flex items-center mb-2">
                    <input
                        type="text"
                        value={interest}
                        onChange={(e) => handleCentreInteretChange(index, e)}
                        className="w-full py-2 px-4 rounded-lg border"
                        placeholder="Centre d'intérêt"
                    />
                    {formData.centreinteret.length > 1 && (
                        <button type="button" onClick={() => removeCentreInteret(index)} className="ml-2 text-red-600 hover:text-red-800">
                            Supprimer
                        </button>
                    )}
                </div>
            ))}
            {formData.centreinteret.length < 6 ? (
                <button
                    type="button"
                    onClick={addCentreInteret}
                    className="w-full py-2 bg-green-600 text-white rounded-lg shadow-md hover:bg-green-700 transition duration-300 ease-in-out"
                >
                    Ajouter un centre d'intérêt
                </button>
            ) : (
                <p className="text-gray-600 mt-2">Vous avez atteint le nombre maximum de centres d'intérêt.</p>
            )}
        </div>

        <button type="submit" className="w-full py-3 bg-indigo-700 text-white font-bold rounded-lg shadow-md hover:bg-indigo-800 transition duration-300 ease-in-out mt-6">
            Terminer
        </button>
    </form>
)}

                </div>
            </div>

            <Footer />
        </div>
    );
};

export default CVBasicForm;
