import React from 'react';
import { useNavigate } from 'react-router-dom';
import PulsatingButton from '../components/buttons/pulsating-button';
import TypingAnimation from '../components/animations/typing-animation';
import { useAuth } from '../contexts/AuthContext';
import NavbarLoginYes from '../components/navbars/navbar_login_yes';
import NavbarNotLoginYet from '../components/navbars/navbar_notlogin_yet';
import Footer from '../components/footer/footer';

const LandingPage = () => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();

    const navigateToForm = () => {
        navigate('/generate-cv');
    };

        

    return (
        <div className="h-screen overflow-y-auto bg-gradient-to-r from-blue-800 to-indigo-900">
                {isAuthenticated ? <NavbarLoginYes /> : <NavbarNotLoginYet />}
                {/* Landing Page Content */}
                <div className="flex justify-between items-center mt-36 px-[12%] space-x-12 relative">
                    {/* Text Section */}
                    <div className="bg-white p-10 rounded-2xl shadow-xl w-1/2 max-w-lg z-20">
                        <TypingAnimation
                            className="text-5xl font-bold text-blue-800 mb-6 leading-tight"
                            text="Bienvenue sur CVflash.fr"
                        />
                        <p className="text-gray-600 text-lg mb-8 leading-relaxed">
                            Créez facilement votre CV professionnel en quelques étapes. Que vous soyez à la recherche d'un emploi ou souhaitiez mettre à jour votre CV, notre outil est là pour vous simplifier la tâche et vous offrir un rendu élégant.
                        </p>
                        <PulsatingButton
                            onClick={navigateToForm}
                            pulseColor="#0096ff"
                            duration="1.5s"
                        >
                            Génerer votre CV
                        </PulsatingButton>
                    </div>

                    {/* Image Section */}
                    <div className="relative w-1/4 max-w-xs z-20">
                        {/* Dot Section */}
                        <div className="absolute -bottom-8 -left-8 grid grid-cols-5 grid-rows-5 gap-4 z-0">
                            {Array.from({ length: 25 }).map((_, index) => (
                                <div key={index} className="w-1 h-1 bg-white rounded-full"></div>
                            ))}
                        </div>
                        <div className="absolute -top-8 -right-8 grid grid-cols-5 grid-rows-5 gap-4 z-0">
                            {Array.from({ length: 25 }).map((_, index) => (
                                <div key={index} className="w-1 h-1 bg-white rounded-full"></div>
                            ))}
                        </div>

                        <img
                            src={require('../components/img/excv.png')}
                            alt="Exemple de CV"
                            className="rounded-3xl shadow-lg object-cover w-full z-20 relative"
                        />
                    </div>
                    <div className="absolute top-0 right-0 w-96 h-96 bg-blue-400 rounded-full blur-3xl opacity-30 transform translate-x-20 -translate-y-20 z-10"></div>
                    <div className="absolute top-3/4 left-[44%] transform -translate-x-[70%] -translate-y-1/2 w-72 h-72 bg-blue-300 rounded-full blur-2xl opacity-30 z-0"></div>
                </div>

               

            {/* Divider */}
            <div className="flex justify-center py-10"></div>

                {/* Section Témoignages */}
                <div className="flex justify-center py-20 bg-gray-100">
                    <div className="bg-white rounded-lg shadow-2xl p-12 max-w-6xl mx-auto w-full">
                        <div className="max-w-2xl mx-auto text-center">
                            <h2 className="text-3xl font-extrabold text-gray-800">Ce que nos clients pensent de nous</h2>
                            <p className="text-sm mt-4 leading-relaxed text-gray-800">
                                Nos utilisateurs ont trouvé leur emploi idéal grâce à un CV professionnel et bien structuré. Découvrez leurs retours d'expérience !
                            </p>
                        </div>
                        <div className="grid md:grid-cols-3 gap-8 max-md:gap-12 max-md:justify-center text-center max-md:max-w-lg mx-auto mt-16">
                            {[
                                { name: "Jean Dupont", position: "Développeur Web", image: "https://readymadeui.com/team-2.webp", feedback: "Grâce à ce site, j'ai pu créer un CV professionnel en quelques minutes. J'ai trouvé un emploi en moins de deux semaines !" },
                                { name: "Marie Leblanc", position: "Chef de Projet", image: "https://readymadeui.com/team-3.webp", feedback: "Le générateur de CV est intuitif et le rendu est impeccable. Mon CV a attiré l'attention de nombreux recruteurs, et j'ai rapidement décroché des entretiens." },
                                { name: "Paul Martin", position: "Designer", image: "https://readymadeui.com/team-4.webp", feedback: "Un excellent outil ! Mon CV avait toujours l'air basique, mais ici j'ai pu créer un CV moderne et impactant. J'ai trouvé un emploi peu de temps après." },
                            ].map((client, index) => (
                                <div className="bg-white rounded-lg shadow-lg p-6" key={index}>
                                    <div className="flex flex-col items-center">
                                        <img src={client.image} className="w-24 h-24 rounded-full shadow-md border-2 border-white" alt={`${client.name}`} />
                                        <div className="mt-4">
                                            <h4 className="text-sm font-extrabold text-gray-800">{client.name}</h4>
                                            <p className="text-xs text-blue-600 font-bold mt-1">{client.position}</p>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <p className="text-sm leading-relaxed text-gray-800">
                                            {client.feedback}
                                        </p>
                                    </div>
                                    <div className="flex justify-center space-x-1 mt-4">
                                        {[...Array(5)].map((_, i) => (
                                            <svg key={i} className="w-4 fill-blue-500" viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 0L9.4687 3.60213L13.6574 4.83688L10.9944 8.29787L11.1145 12.6631L7 11.2L2.8855 12.6631L3.00556 8.29787L0.342604 4.83688L4.5313 3.60213L7 0Z" />
                                            </svg>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>




            {/* What is a CV Section */}
            <div className="flex justify-center py-16 bg-gradient-to-r from-blue-800 to-indigo-900">
                <div className="max-w-4xl mx-auto text-center bg-white p-10 rounded-lg shadow-2xl">
                    <h2 className="text-4xl font-extrabold text-blue-900 mb-6">Qu'est-ce qu'un CV ?</h2>
                    <p className="text-lg leading-relaxed text-gray-900">
                        Un <span className="font-semibold text-blue-800">Curriculum Vitae (CV)</span> est un document essentiel qui résume l'expérience professionnelle, les compétences, l'éducation, et les réalisations d'un individu. Il constitue le premier aperçu que les employeurs ont d'un candidat, leur permettant d'évaluer rapidement son adéquation pour un poste.
                        <br /><br />
                        Bien structuré et bien rédigé, le CV est l'outil le plus puissant pour se démarquer dans un marché du travail compétitif. Il met en lumière vos points forts, démontre votre valeur et vous aide à faire une excellente première impression. Que vous soyez un professionnel chevronné ou que vous veniez de commencer votre carrière, un bon CV est la clé pour ouvrir de nouvelles opportunités.
                    </p>
                </div>
            </div>

            {/* What to Include in a CV Section */}
            <div className="flex justify-center py-16 bg-gradient-to-r from-blue-800 to-indigo-900">
                <div className="max-w-4xl mx-auto text-center bg-white p-10 rounded-lg shadow-2xl">
                    <h2 className="text-4xl font-extrabold text-blue-900 mb-6">Que dois-je mentionner sur mon curriculum vitae ?</h2>
                    <p className="text-lg leading-relaxed text-gray-900">
                        Votre <span className="font-semibold text-blue-800">Curriculum Vitae</span> doit présenter clairement vos informations professionnelles les plus importantes. Voici les sections clés à inclure :
                        <ul className="mt-4 space-y-3 text-left pl-8 list-disc">
                            <li><span className="font-semibold">Informations personnelles :</span> Votre nom, coordonnées, et toute autre information de contact essentielle.</li>
                            <li><span className="font-semibold">Résumé professionnel :</span> Un bref aperçu de votre profil et de vos compétences principales.</li>
                            <li><span className="font-semibold">Expérience professionnelle :</span> Listez vos emplois précédents en mentionnant les responsabilités et réalisations clés.</li>
                            <li><span className="font-semibold">Éducation :</span> Indiquez vos diplômes, certifications, et formations pertinentes.</li>
                            <li><span className="font-semibold">Compétences :</span> Mettez en avant vos compétences techniques et interpersonnelles adaptées au poste visé.</li>
                            <li><span className="font-semibold">Langues :</span> Mentionnez les langues que vous maîtrisez et leur niveau de compétence.</li>
                            <li><span className="font-semibold">Centres d'intérêt :</span> Montrez votre personnalité et des passions qui peuvent enrichir votre profil professionnel.</li>
                        </ul>
                        <br />
                        En ajoutant ces sections, vous donnerez aux recruteurs un aperçu complet de votre parcours et de vos compétences, maximisant ainsi vos chances d'être retenu.
                    </p>
                </div>
            </div>

            {/* How to Generate a CV Section */}
            <div className="flex justify-center py-16 bg-gradient-to-r from-blue-800 to-indigo-900">
                <div className="max-w-4xl mx-auto text-center bg-white p-10 rounded-lg shadow-2xl">
                    <h2 className="text-4xl font-extrabold text-blue-900 mb-6">Comment générer un CV avec notre outil ?</h2>
                    <p className="text-lg leading-relaxed text-gray-900">
                        Notre outil de création de CV est conçu pour simplifier le processus et vous permettre de créer un <span className="font-semibold text-blue-800">CV professionnel</span> en quelques étapes simples :
                        <ul className="mt-4 space-y-3 text-left pl-8 list-decimal">
                            <li><span className="font-semibold">S'inscrire ou se connecter :</span> Créez un compte ou connectez-vous pour accéder à l'outil.</li>
                            <li><span className="font-semibold">Choisir un modèle :</span> Sélectionnez un modèle de CV qui correspond à votre style et à votre secteur d'activité.</li>
                            <li><span className="font-semibold">Remplir les sections :</span> Renseignez les informations dans chaque section : expérience, compétences, éducation, etc.</li>
                            <li><span className="font-semibold">Personnaliser le design :</span> Ajoutez des touches personnelles, comme une photo, des couleurs et des polices.</li>
                            <li><span className="font-semibold">Télécharger :</span> Une fois satisfait, téléchargez votre CV au format PDF prêt à être envoyé aux employeurs.</li>
                        </ul>
                        <br />
                        Avec notre interface intuitive, vous serez guidé à chaque étape pour produire un CV qui attire l'attention des recruteurs et reflète fidèlement votre parcours.
                    </p>
                </div>
            </div>

                        {/* About Section */}
            <div id="about" className="flex justify-center py-20 bg-gray-100">           
                <section className="py-24 relative bg-white rounded-lg shadow-lg w-full max-w-7xl mx-0">
                    <div className="px-4 md:px-5 lg:px-5 mx-auto">
                        <div className="w-full justify-start items-center gap-12 grid lg:grid-cols-2 grid-cols-1">
                            <div className="w-full justify-center items-start gap-6 grid sm:grid-cols-2 grid-cols-1 lg:order-first order-last">
                                <div className="pt-24 lg:justify-center sm:justify-end justify-start items-start gap-2.5 flex">
                                    <img
                                        className="rounded-xl object-cover shadow-lg"
                                        src={require('../components/img/CV4.png')}
                                        alt="About Us image"
                                    />
                                </div>
                                <img
                                    className="sm:ml-0 ml-auto rounded-xl object-cover shadow-lg"
                                    src={require('../components/img/Cv3.png')}
                                    alt="About Us image"
                                />
                            </div>
                            <div className="w-full flex-col justify-center lg:items-start items-center gap-10 inline-flex">
                                <div className="w-full flex-col justify-center items-start gap-8 flex">
                                    <div className="w-full flex-col justify-start lg:items-start items-center gap-3 flex">
                                        <h2 className="text-blue-900 text-4xl font-bold leading-normal lg:text-start text-center">
                                            Qui sommes-nous ?
                                        </h2>
                                        <p className="text-gray-700 text-base leading-relaxed lg:text-start text-center">
                                        CVflash.fr est dédié à simplifier la création de CV pour tous. Nous mettons à disposition des outils innovants et intuitifs pour vous aider à présenter votre parcours de manière professionnelle et attrayante.
                                        </p>
                                    </div>
                                    <div className="w-full lg:justify-start justify-center items-center sm:gap-10 gap-5 inline-flex">
                                        <div className="flex-col justify-start items-start inline-flex">
                                            <h3 className="text-blue-900 text-4xl font-bold">150+</h3>
                                            <h6 className="text-gray-600 text-base leading-relaxed">Cv génerés</h6>
                                        </div>
                                        <div className="flex-col justify-start items-start inline-flex">
                                            <h4 className="text-blue-900 text-4xl font-bold">125+</h4>
                                            <h6 className="text-gray-600 text-base leading-relaxed">Clients satisfaits</h6>
                                        </div>
                                    </div>
                                </div>
                                <button className="sm:w-fit w-full px-5 py-3 bg-indigo-700 hover:bg-indigo-800 transition-all duration-300 ease-in-out rounded-lg shadow-md text-white font-medium" onClick={() => navigate('/Contact')}>
                                    Nous contacter
                                </button>
                            </div>
                        </div>
                    </div>
                </section>                
            </div>                        

            <Footer />

        </div>
        
    );
};

export default LandingPage;